export const downloadFile = (filename, file) => {
  var element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const parseYoutubeLink = (youtubeLink) => {
  if (!youtubeLink) return;

  if (youtubeLink.includes('watch?v=')) {
    return `https://www.youtube.com/embed/${youtubeLink.slice(youtubeLink.indexOf('?v=') + 3)}`;
  }
  if (youtubeLink.includes('youtu.be')) {
    return `https://www.youtube.com/embed/${youtubeLink.slice(
      youtubeLink.indexOf('youtu.be/') + 9
    )}`;
  }
};

const PERIOD_IN_DAYS = 7;

export const getDatesForDaysCountBeforehend = (daysInAdvance = 52) => {
  // 10/06/2025
  const currentDate = new Date();
  const initialDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
const endDate = new Date(initialDate);
  endDate.setDate(endDate.getDate() + daysInAdvance * PERIOD_IN_DAYS);

  return Array.from({ length: daysInAdvance * PERIOD_IN_DAYS })
    .map((_, idx) => {
      const transformedDate = new Date(initialDate);
      transformedDate.setDate(initialDate.getDate() + idx + 1);

      if (transformedDate > currentDate && transformedDate.getDay() === 1) {
        const transformedMonth = transformedDate.getMonth() + 1;
        const formattedDate = `${transformedDate
          .getDate()
          .toString()
          .padStart(2, '0')}/${transformedMonth
          .toString()
          .padStart(2, '0')}/${transformedDate.getFullYear()}`;

        return { value: formattedDate, label: formattedDate };
      }
      return null;
    })
    .filter((item) => item !== null);
};
